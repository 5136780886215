<script setup lang="ts">
import { flattenEntity } from 'strapi-flatten-graphql'
import type { HeroPageV2 } from '~/models/Strapi'

interface Props {
  datas: any
}

const props = defineProps<Props>()
const config = useRuntimeConfig()
const isLangModalOpen = useCookie('isLangModalOpen', { path: '/', domain: config.public.COOKIE_DOMAIN })
const showPosition = ref(0)
const timer = ref(3)
const animation = props.datas.animation.map(item => ({
  id: item.id,
  total: item.total,
  description: item.description,
  icons: item.icons.data.map(icon => icon.attributes.url),
}))
const flattenData = { ...props.datas, image: flattenEntity(props.datas?.image?.data)?.url, animation } as HeroPageV2

const { pause, resume, isActive } = useIntervalFn(() => {
  if (timer.value <= 0) {
    timer.value = 3
    showPosition.value = (showPosition.value + 1) > (animation.length - 1) ? 0 : showPosition.value + 1
  }

  timer.value--
}, 1000)
</script>

<template>
  <BaseAtomsSkeletonBounded :class="`${flattenData?.max_width ? flattenData?.max_width : 'px-5 !lg:p-0'}`" class="text-gray-900" container="full">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6">
      <div>
        <h1 class="text-4xl <md:px-2 <md:text-center md:text-5xl lg:text-7xl font-bold mb-4 lg:mb-6">
          {{ flattenData?.title }}
        </h1>
        <img :src="flattenData?.image" class="<md:block md:hidden py-2.5 px-4 mb-4">
        <div :class="`${isEmpty(useGetSubdomain()) ? 'mb-4' : 'mb-6 mmd:mb-10 lg:mb-14'} text-sm md:text-base lg:text-lg <md:text-center text-gray-600`">
          {{ flattenData?.description }}
        </div>

        <!-- ANIMATION SECTION -->
        <div v-if="isEmpty(useGetSubdomain())" class="h-18 md:h-12 md:my-8 lg:my-10 <lg:mb-6 relative">
          <div v-for="(item, idx) in flattenData?.animation" :key="idx" class="w-full lg:max-w-[450px] md:text-base sm:text-lg text-lg absolute">
            <Transition>
              <div v-show="idx === showPosition" class="md:flex items-center text-center bg-gray-50 w-full py-2 md:pl-4">
                <span class="font-bold mr-1">{{ item?.total }}</span>
                <span>{{ item?.description }}</span>
                <div class="flex items-center space-x-2 mx-auto <md:mt-2 lg:ml-auto" style="width: fit-content">
                  <img v-for="(icon) in item.icons" :key="icon" :src="icon" class="w-5 h-5">
                </div>
              </div>
            </Transition>
          </div>
        </div>

        <!-- BUTTON SECTION -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
          <BaseAtomsButton v-if="flattenData?.button_label" size="sm" :to="flattenData?.button_url" class="text-sm font-bold bg-[#5843E5] border-[#5843E5]">
            {{ flattenData?.button_label }}
          </BaseAtomsButton>
          <BaseAtomsButton v-if="(useGetSubdomain() === 'cn' || useGetSubdomain() === 'hk') && !isEmpty(flattenData?.button_employer_label)" target="_blank" outline size="sm" class="text-sm font-bold border-[#5843E5]" :to="flattenData?.button_employer_url">
            {{ flattenData?.button_employer_label }}
          </BaseAtomsButton>
          <BaseAtomsButton v-if="isEmpty(useGetSubdomain())" outline size="sm" class="text-sm font-bold border-[#5843E5]" @click="isLangModalOpen = 'true'">
            <div class="flex-shrink-0 flex mr-2">
              <Icon name="material-symbols:language" class="w-5 h-5" />
            </div>
            Choose Region
          </BaseAtomsButton>
        </div>
      </div>
      <img :src="flattenData?.image" class="<md:hidden md:block">
    </div>
  </BaseAtomsSkeletonBounded>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
